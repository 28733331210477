<template>
  <section id="top-customers">
    <b-card>
      <b-row>
        <b-col xl="3" md="3">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
            <b-input-group-append>
              <b-button variant="outline-danger" @click="clearDateRange">
                <feather-icon icon="XIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col xl="3" md="3">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search..." @keyup.enter="searchCustomer" />
          </b-input-group>
        </b-col>
        <b-col xl="3" md="3">
          <v-select v-model="selectedPlatform" label="title" placeholder="Platform" :options="allPlatforms"
            @input="changeSelectedPlatform" />
        </b-col>
        <b-col xl="3" md="3">
          <v-select v-model="selectedCustomerGroup" label="title" placeholder="Customer group"
            :options="allCustomerGroups" @input="changeSelectedCustomerGroup" />
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col xl="3" md="3">
          <v-select v-model="selectedCustomerCategory" label="title" placeholder="Customer category"
            :options="allCustomerCategories" @input="changeSelectedCustomerCategory" />
        </b-col>
        <b-col xl="3" md="3">
          <v-select v-model="selectedCustomerStatus" label="title" placeholder="Customer status"
            :options="allCustomerStatuses" @input="changeSelectedCustomerStatus" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Top customers
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-top-customers" />
          </h4>
          <b-popover target="popover-top-customers" triggers="hover" placement="bottom">
            <span>The 'Top customers' section features a table listing top customers with detailed information,
              including customer number, name, group, category, order count, turnover, margin, classification, and more.
              Filters are available for date range, customer name or number search, customer group, category, and
              platform. Clicking on a table row opens a modal with customer details, including main characteristics,
              tables of Purchased Items and Inquired Items, and an option to add notes with the last contact date.
            </span>
          </b-popover>
          <b-card-text class="mb-0 w-50">
            <b-row>
              <b-col xl="11" md="11">
                <v-select v-model="visibleTopCustomersTableColumns" :options="allTopCustomersTableFields" label="label"
                  multiple placeholder="Select columns" :reduce="option => option.key" />
              </b-col>
              <b-col xl="1" md="1">
                <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px"
                  rounded="sm">
                  <div v-if="userRole === 'Admin'">
                    <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                      @click="exportTable" />
                  </div>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="topCustomersTable.currentPage"
          :items="topCustomersTable.items" :fields="filteredTopCustomersTableFields"
          :sort-by.sync="topCustomersTable.sortBy" :sort-desc.sync="topCustomersTable.sortDesc"
          :sort-direction="topCustomersTable.sortDirection" :filter="topCustomersTable.filter"
          :filter-included-fields="topCustomersTable.filterOn" @sort-changed="sortChanged" @row-clicked="rowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="topCustomersTable.currentPage" :total-rows="topCustomersTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleTopCustomersTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="customer-details-modal" size="xl" title="Customer Details" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetCustomerDetailsModal">
      <b-overlay :show="showCustomerDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-row>
          <b-col xl="5">
            <b-card no-body bg-variant="primary" text-variant="white">
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder text-white">
                    {{ customerDetails.company_name }}
                  </h2>
                  <span>Customer</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="2">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ customerDetails.customer_no }}
                  </h2>
                  <span>Customer No.</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ customerDetails.email }}
                  </h2>
                  <span>Email</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="2">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ customerDetails.customer_since | formatDateTodmY }}
                  </h2>
                  <span>Customer since</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="3">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ customerDetails.turnover_prev_3_year | formatNumber }}
                  </h2>
                  <span>Turnover {{ new Date().getFullYear() - 3 }}</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ customerDetails.turnover_prev_2_year | formatNumber }}
                  </h2>
                  <span>Turnover {{ new Date().getFullYear() - 2 }}</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ customerDetails.turnover_prev_year | formatNumber }}
                  </h2>
                  <span>Turnover {{ new Date().getFullYear() - 1 }}</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3">
            <div v-if="customerDetails.turnover_this_year > customerDetails.turnover_prev_year">
              <b-card no-body bg-variant="info" text-variant="white">
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder text-white">
                      {{ customerDetails.turnover_this_year | formatNumber }}
                    </h2>
                    <span>Turnover {{ new Date().getFullYear() }}</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div v-else>
              <b-card no-body bg-variant="danger" text-variant="white">
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder text-white">
                      {{ customerDetails.turnover_this_year | formatNumber }}
                    </h2>
                    <span>Turnover {{ new Date().getFullYear() }}</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col xl="4">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder" v-if="customerStatus.title">
                    {{ customerStatus.title }}
                  </h2>
                  <h2 class="mb-25 font-weight-bolder" v-else>
                    No status
                  </h2>
                  <span>Customer status</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="4">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder" v-if="customerStatus.status_changed_at">
                    {{ customerStatus.status_changed_at | formatDateTodmY }}
                  </h2>
                  <h2 class="mb-25 font-weight-bolder" v-else>
                    No status changed date
                  </h2>
                  <span>Customer status changed at</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="4">
            <b-card no-body>
              <b-card-body>
                <b-form @submit.prevent="updateStatus">
                  <b-form-group label="Status" label-for="newStatus">
                    <v-select v-model="newStatus" label="title" :options="allCustomerStatuses" />
                  </b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block" type="submit"
                    v-if="customerDetails.status">
                    Update Status
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block" type="submit"
                    v-else>
                    Set Status
                  </b-button>
                </b-form>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="8">
            <b-overlay :show="showNotes" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <b-card no-body>
                <b-card-header>
                  <h4 class="mb-0">
                    Notes
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                      id="popover-customer-notes" />
                  </h4>
                  <b-popover target="popover-customer-notes" triggers="hover" placement="bottom">
                    <span>Notes</span>
                  </b-popover>
                </b-card-header>
                <b-table striped hover responsive class="position-relative"
                  :current-page="customerNotesTable.currentPage" :items="customerNotesTable.items"
                  :fields="customerNotesTable.fields" :sort-by.sync="customerNotesTable.sortBy"
                  :sort-desc.sync="customerNotesTable.sortDesc" :sort-direction="customerNotesTable.sortDirection"
                  :filter="customerNotesTable.filter" :filter-included-fields="customerNotesTable.filterOn" />
                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                  <!-- pagination -->
                  <div>
                    <b-pagination v-model="customerNotesTable.currentPage" :total-rows="customerNotesTable.totalRows"
                      first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                      @change="handleCustomerNotesTablePageChange">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
          <b-col xl="4">
            <b-card no-body>
              <b-card-body>
                <b-form @submit.prevent="addNewNote">
                  <b-form-group label="Notes" label-for="newNoteText">
                    <b-input-group class="input-group-merge"
                      :class="validationItem(newNote.newNoteText) ? 'is-valid' : 'is-invalid'">
                      <b-form-textarea id="newNoteText" v-model="newNote.newNoteText" rows="4" />
                    </b-input-group>
                    <b-form-invalid-feedback v-if="submittedNewNote && !validationItem(newNote.newNoteText)">
                      Empty string
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group label="Contacted date" label-for="newNoteContactedDate">
                    <flat-pickr id="newNoteContactedDate" v-model="newNote.newNoteContactedDate" class="form-control" />
                  </b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                    type="submit">
                    Add Note
                  </b-button>
                </b-form>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Greyhound Emails
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-greyhound-emails" />
          </h4>
          <b-popover target="popover-greyhound-emails" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="greyhoundEmailsTable.currentPage"
          :items="greyhoundEmailsTable.items" :fields="greyhoundEmailsTable.fields" :per-page="10"
          :sort-by.sync="greyhoundEmailsTable.sortBy" :sort-desc.sync="greyhoundEmailsTable.sortDesc"
          :sort-direction="greyhoundEmailsTable.sortDirection" :filter="greyhoundEmailsTable.filter"
          :filter-included-fields="greyhoundEmailsTable.filterOn" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="greyhoundEmailsTable.currentPage" :total-rows="greyhoundEmailsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-overlay :show="showPurchasedItems" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Purchased items
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-customer-purchased-items" />
                </h4>
                <b-popover target="popover-customer-purchased-items" triggers="hover" placement="bottom">
                  <span>The 'Purchased items' section displays a table listing items purchased by the selected customer,
                    with columns for SKU, name, quantity sold, and more.</span>
                </b-popover>
              </b-card-header>
              <b-table striped hover responsive class="position-relative"
                :current-page="purchasedItemsTable.currentPage" :items="purchasedItemsTable.items"
                :fields="purchasedItemsTable.fields" :sort-by.sync="purchasedItemsTable.sortBy"
                :sort-desc.sync="purchasedItemsTable.sortDesc" :sort-direction="purchasedItemsTable.sortDirection"
                :filter="purchasedItemsTable.filter" :filter-included-fields="purchasedItemsTable.filterOn" />
              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- pagination -->
                <div>
                  <b-pagination v-model="purchasedItemsTable.currentPage" :total-rows="purchasedItemsTable.totalRows"
                    first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                    @change="handlePurchasedItemsTablePageChange">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col xl="6" md="6">
          <b-overlay :show="showInquiredItems" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Inquired items
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-customer-inquired-items" />
                </h4>
                <b-popover target="popover-customer-inquired-items" triggers="hover" placement="bottom">
                  <span>The 'Inquired items' section displays a table listing items inquired by the selected customer,
                    with
                    columns for SKU, name, quantity sold, and more.</span>
                </b-popover>
              </b-card-header>
              <b-table striped hover responsive class="position-relative" :current-page="inquiredItemsTable.currentPage"
                :items="inquiredItemsTable.items" :fields="inquiredItemsTable.fields"
                :sort-by.sync="inquiredItemsTable.sortBy" :sort-desc.sync="inquiredItemsTable.sortDesc"
                :sort-direction="inquiredItemsTable.sortDirection" :filter="inquiredItemsTable.filter"
                :filter-included-fields="inquiredItemsTable.filterOn" />
              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- pagination -->
                <div>
                  <b-pagination v-model="inquiredItemsTable.currentPage" :total-rows="inquiredItemsTable.totalRows"
                    first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                    @change="handleInquiredItemsTablePageChange">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-modal>
    <b-overlay :show="showCustomersToRemind" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Need to contact customers
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-customers-to-remind" />
          </h4>
          <b-popover target="popover-customers-to-remind" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="customersToRemindTable.currentPage"
          :items="customersToRemindTable.items" :fields="customersToRemindTable.fields"
          :sort-by.sync="customersToRemindTable.sortBy" :sort-desc.sync="customersToRemindTable.sortDesc"
          :sort-direction="customersToRemindTable.sortDirection" :filter="customersToRemindTable.filter"
          :filter-included-fields="customersToRemindTable.filterOn" @row-clicked="rowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="customersToRemindTable.currentPage" :total-rows="customersToRemindTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleCustomersToRemindTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BOverlay,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BPopover,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axios from 'axios';
import * as XLSX from 'xlsx';

const currentDate = new Date();
const startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BOverlay,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BPopover,
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userRole: '',
      show: true,
      showCustomersToRemind: true,
      showCustomerDetails: true,
      showPurchasedItems: true,
      showNotes: true,
      showInquiredItems: true,
      showExportStart: false,
      oldDateRange: `${startDate.getFullYear()}-${formatMonth(startDate)}-${formatDate(startDate)} to ${endDate.getFullYear()}-${formatMonth(endDate)}-${formatDate(endDate)}`,
      dateRange: `${startDate.getFullYear()}-${formatMonth(startDate)}-${formatDate(startDate)} to ${endDate.getFullYear()}-${formatMonth(endDate)}-${formatDate(endDate)}`,
      searchValue: '',
      selectedCustomerGroup: '',
      allCustomerGroups: [],
      allCustomerStatuses: [],
      selectedCustomerCategory: '',
      selectedCustomerStatus: '',
      allCustomerCategories: [],
      selectedPlatform: '',
      allPlatforms: [],
      customerStatus: { id: null, title: null, status_changed_at: null },
      newStatus: { id: null, title: null },
      queryParams: {},
      queryCustomerNotes: {},
      queryParamsPurchasedItems: {},
      queryParamsInquiredItems: {},
      queryParamsCustomersToRemind: {},
      customerNo: '',
      customerID: null,
      customerEmail: '',
      allTopCustomersTableFields: [
        { key: 'customer_no', label: 'customer no', sortable: true },
        { key: 'email', label: 'customer email', sortable: true },
        { key: 'company_name', label: 'company name', sortable: true },
        { key: 'customer_group', label: 'customer group', sortable: true },
        { key: 'customer_category', label: 'customer category', sortable: true },
        { key: 'industry', label: 'industry', sortable: true },
        { key: 'status', label: 'status', sortable: true },
        { key: 'platform', label: 'platform', sortable: true },
        { key: 'orders_count', label: 'orders', sortable: true },
        { key: 'offers_count', label: 'offers', sortable: true },
        {
          key: 'open_orders_amount',
          label: 'open orders',
          sortable: true,
          formatter: val => val !== null ? this.$formatNumber(val) : ''
        },
        {
          key: 'turnover',
          label: 'turnover',
          sortable: true,
          formatter: val => val !== null ? `€ ${this.$formatNumber(val)}` : ''
        },
        {
          key: 'turnover_prev_date_range',
          label: 'turnover prev date range',
          sortable: true,
          formatter: val => val !== null ? `€ ${this.$formatNumber(val)}` : ''
        },
        {
          key: 'turnover_diff',
          label: 'turnover difference',
          sortable: true,
          formatter: val => val !== null ? `€ ${this.$formatNumber(val)}` : ''
        },
        {
          key: 'turnover_diff_p',
          label: 'turnover difference %',
          sortable: true,
          formatter: val => val !== null ? `${this.$formatNumber(val)} %` : ''
        },
        {
          key: 'margin',
          label: 'margin',
          sortable: true,
          formatter: val => val !== null ? `€ ${this.$formatNumber(val)}` : ''
        },
        {
          key: 'margin_p',
          label: 'margin %',
          sortable: true,
          formatter: val => val !== null ? `${this.$formatNumber(val)} %` : ''
        },
        {
          key: 'frequency',
          label: 'frequency',
          sortable: true,
          formatter: val => `${this.$formatNumber(val)}`
        },
        {
          key: 'share_total_sales',
          label: 'share of total sales',
          sortable: true,
          formatter: val => `${this.$formatNumber(val)}`
        },
        { key: 'class', label: 'classification', sortable: true },
        {
          key: 'last_order_from',
          label: 'last order',
          sortable: true,
          formatter: value => value ? new Date(value).toLocaleDateString('de-DE') : ''
        },
        { key: 'no_contact_days', label: 'no contact (days)', sortable: true },
        {
          key: 'last_contact',
          label: 'last contact',
          sortable: true,
          formatter: value => {
            if (value && value !== 'NaT') {
              const new_date = new Date(value);
              return new_date.toLocaleDateString('de-DE');
            }
            return '';
          },
        },
        { key: 'last_contact_by', label: 'last contact by', sortable: true },
      ],
      // List of visible field keys
      visibleTopCustomersTableColumns: [
        'customer_no', 'email', 'company_name', 'customer_group', 'customer_category', 'industry',
        'status', 'platform', 'orders_count', 'offers_count', 'open_orders_amount', 'turnover',
        'turnover_prev_date_range', 'turnover_diff', 'turnover_diff_p', 'margin', 'margin_p',
        'frequency', 'share_total_sales', 'class', 'last_order_from', 'no_contact_days',
        'last_contact', 'last_contact_by',
      ],
      topCustomersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        items: [],
      },
      customerDetails: {},
      greyhoundEmailsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'number', label: 'number', sortable: true },
          { key: 'sender', label: 'sender', sortable: true },
          { key: 'topic_name', label: 'topic name', sortable: true },
          { key: 'group_name', label: 'group name', sortable: true },
          { key: 'state', label: 'state', sortable: true },
          { key: 'username', label: 'contact by', sortable: true },
          {
            key: 'created',
            label: 'contacted date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      submittedNewNote: false,
      newNote: { newNoteText: null, newNoteContactedDate: currentDate },
      customerNotesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'contacted',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'note', label: 'note', sortable: true },
          { key: 'full_name', label: 'created by', sortable: true },
          {
            key: 'contacted',
            label: 'contacted date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      purchasedItemsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total_turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'total_turnover',
            label: 'total turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'qty_sold', label: 'qty sold', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      inquiredItemsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total_turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'total_turnover',
            label: 'total turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'qty_sold', label: 'qty sold', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      customersToRemindTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'status_changed_at',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'number', label: 'customer no', sortable: true },
          { key: 'customer_status_name', label: 'status', sortable: true },
          {
            key: 'status_changed_at',
            label: 'status changed',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    const from_date_str = dateRange[0];
    const to_date_str = dateRange[1];
    const from_date = new Date(from_date_str);
    const to_date = new Date(to_date_str);

    const from_date_new = from_date;
    from_date_new.setFullYear(from_date_new.getFullYear() - 1);
    const from_date_prev_year = new Date(from_date_new);
    const to_date_new = to_date;
    to_date_new.setFullYear(to_date_new.getFullYear() - 1);
    const to_date_prev_year = new Date(to_date_new);

    this.queryParams.from_date = from_date_str;
    this.queryParams.to_date = to_date_str;
    this.queryParams.from_date_prev_year = `${from_date_prev_year.getFullYear()}-${formatMonth(from_date_prev_year)}-${formatDate(from_date_prev_year)}`;
    this.queryParams.to_date_prev_year = `${to_date_prev_year.getFullYear()}-${formatMonth(to_date_prev_year)}-${formatDate(to_date_prev_year)}`;

    this.userRole = localStorage.getItem('userRole');

    try {
      await this.getJTLCustomerGroups();
      await this.getCustomerStatuses();
      await this.getAllCustomerCategories();
      await this.getPlatforms();
      await this.getTopCustomers();
      await this.getCustomersToRemind();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  computed: {
    filteredTopCustomersTableFields() {
      return this.allTopCustomersTableFields.filter(field =>
        this.visibleTopCustomersTableColumns.includes(field.key)
      );
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getJTLCustomerGroups() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/jtl-customer-groups/`, {});
        this.allCustomerGroups = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getCustomerStatuses() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/customer-statuses/`, {});
        this.allCustomerStatuses = response.data.map(item => ({ id: item.id, title: item.name }));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getAllCustomerCategories() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/jtl-customer-categories/`, {});
        this.allCustomerCategories = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getPlatforms() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/platforms/`, {});
        this.allPlatforms = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getTopCustomers() {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/top-customers/`, this.queryParams);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.topCustomersTable.items = results;
          this.topCustomersTable.totalRows = results[0].count * 2;
        }
        else {
          this.topCustomersTable.items = [];
          this.topCustomersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async getCustomersToRemind() {
      this.showCustomersToRemind = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/customers-to-remind/`, this.queryParamsCustomersToRemind);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.customersToRemindTable.items = results;
          this.customersToRemindTable.totalRows = response.data.count * 2;
        }
        else {
          this.customersToRemindTable.items = [];
          this.customersToRemindTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showCustomersToRemind = false;
      }
    },
    async getCustomerDetails() {
      this.showCustomerDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/customer-details/`, { customer_no: this.customerNo });
        this.customerDetails = response.data.results[0];
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showCustomerDetails = false;
      }
    },
    async getCustomerGreyhoundEmails() {
      this.showCustomerDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/customer-emails/`, { email: this.customerEmail });
        const results = response.data.results;

        if (Array.isArray(results) && results.length > 0) {
          this.greyhoundEmailsTable.items = results;
          this.greyhoundEmailsTable.totalRows = response.data.count * 2;
        }
        else {
          this.greyhoundEmailsTable.items = [];
          this.greyhoundEmailsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showCustomerDetails = false;
      }
    },
    async getCustomer() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/customers/`, { search: this.customerNo });
        if (response.data.results.length == 1) {
          this.customerID = response.data.results[0].id;
          this.customerStatus = {
            id: response.data.results[0].customer_status,
            title: response.data.results[0].customer_status_name,
            status_changed_at: response.data.results[0].status_changed_at
          };
          this.newStatus = this.customerStatus;
        }
        else {
          // Create new customer
          const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/customers/`, { number: this.customerNo }, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          });
          this.customerID = response.data.id;
          this.customerStatus = { id: null, title: null, status_changed_at: null };
          this.newStatus = { id: null, title: null };
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getCustomerNotes() {
      this.showNotes = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/customer-notes/`, this.queryCustomerNotes);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.customerNotesTable.items = results;
          this.customerNotesTable.totalRows = response.data.count * 2;
        }
        else {
          this.customerNotesTable.items = [];
          this.customerNotesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showNotes = false;
      }
    },
    async getPurchasedItems() {
      this.showPurchasedItems = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/purchased-items-by-customer/`, this.queryParamsPurchasedItems);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.purchasedItemsTable.items = results;
          this.purchasedItemsTable.totalRows = results[0].count * 2;
        }
        else {
          this.purchasedItemsTable.items = [];
          this.purchasedItemsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showPurchasedItems = false;
      }
    },
    async getInquiredItems() {
      this.showInquiredItems = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/inquired-items-by-customer/`, this.queryParamsInquiredItems);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.inquiredItemsTable.items = results;
          this.inquiredItemsTable.totalRows = results[0].count * 2;
        }
        else {
          this.inquiredItemsTable.items = [];
          this.inquiredItemsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInquiredItems = false;
      }
    },
    async updateStatus() {
      if (this.newStatus) {
        try {
          const response = await axios.patch(`${process.env.VUE_APP_ROOT_API}/customers/${this.customerID}/`, { customer_status: this.newStatus.id }, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          });
          await this.getCustomer();
          this.showToast("success", "Status was set");
        } catch (error) {
          console.log(error)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'login' });
          } else {
            console.error('An error occurred:', error);
            this.showToast("danger", "Something went wrong");
          }
        }
      } else {
        this.showToast("warning", "Empty input fields");
      }
    },
    async addNewNote() {
      this.submittedNewNote = true;
      if (this.newNote.newNoteText && this.newNote.newNoteContactedDate) {
        const params = {
          user: localStorage.getItem('useridLocal'),
          customer: this.customerID,
          note: this.newNote.newNoteText,
          contacted: this.newNote.newNoteContactedDate.toISOString().split('T')[0]
        };

        try {
          const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/customer-notes/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          });
          await this.getCustomerNotes();
          this.showToast("success", "New Note was added");
        } catch (error) {
          console.log(error)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'login' });
          } else {
            console.error('An error occurred:', error);
            this.showToast("danger", "Something went wrong");
          }
        }
      } else {
        this.showToast("warning", "Empty input fields");
      }
    },
    async exportTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-top-customers/`, this.queryParams);
        const exportedData = response.data.results;
        // Filter data to match the visible columns
        const filteredData = exportedData.map(row => {
          const filteredRow = {};
          this.filteredTopCustomersTableFields.forEach(field => {
            filteredRow[field.key] = row[field.key];
          });
          return filteredRow;
        });

        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(filteredData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'TopCustomers.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        const from_date_str = dateRange[0];
        const to_date_str = dateRange[1];
        const from_date = new Date(from_date_str);
        const to_date = new Date(to_date_str);

        const from_date_new = from_date;
        from_date_new.setFullYear(from_date_new.getFullYear() - 1);
        const from_date_prev_year = new Date(from_date_new);
        const to_date_new = to_date;
        to_date_new.setFullYear(to_date_new.getFullYear() - 1);
        const to_date_prev_year = new Date(to_date_new);

        this.queryParams.from_date = from_date_str;
        this.queryParams.to_date = to_date_str;
        this.queryParams.from_date_prev_year = `${from_date_prev_year.getFullYear()}-${formatMonth(from_date_prev_year)}-${formatDate(from_date_prev_year)}`;
        this.queryParams.to_date_prev_year = `${to_date_prev_year.getFullYear()}-${formatMonth(to_date_prev_year)}-${formatDate(to_date_prev_year)}`;

        await this.getTopCustomers();
        this.oldDateRange = this.dateRange;
      }
    },
    clearDateRange() {
      this.dateRange = '';
      this.oldDateRange = '';
      this.queryParams.from_date = '2015-01-01';
      this.queryParams.to_date = new Date().toISOString().split('T')[0];
      this.queryParams.from_date_prev_year = '2014-01-01';
      this.queryParams.to_date_prev_year = '2014-12-31';
      this.getTopCustomers();
    },
    async searchCustomer() {
      this.queryParams.page = 1;
      this.queryParams.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getTopCustomers();
    },
    async changeSelectedCustomerGroup() {
      this.queryParams.customer_group = this.selectedCustomerGroup;
      await this.getTopCustomers();
    },
    async changeSelectedCustomerCategory() {
      this.queryParams.customer_category = this.selectedCustomerCategory;
      await this.getTopCustomers();
    },
    async changeSelectedCustomerStatus() {
      this.queryParams.customer_status = this.selectedCustomerStatus.id;
      await this.getTopCustomers();
    },
    async changeSelectedPlatform() {
      this.queryParams.platform = this.selectedPlatform;
      await this.getTopCustomers();
    },
    async sortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParams.ordering = `-${value.sortBy}`;
      } else {
        this.queryParams.ordering = value.sortBy;
      }
      await this.getTopCustomers();
    },
    async handleTopCustomersTablePageChange(value) {
      this.queryParams.page = value;
      await this.getTopCustomers();
    },
    async handleCustomersToRemindTablePageChange(value) {
      this.queryParamsCustomersToRemind.page = value;
      await this.getCustomersToRemind();
    },
    async rowClicked(row) {
      this.customerNo = row.customer_no ?? row.number;
      this.customerEmail = row.email;

      this.queryCustomerNotes.search = this.customerNo;
      this.queryParamsPurchasedItems.customer_no = this.customerNo;
      this.queryParamsInquiredItems.customer_no = this.customerNo;

      await this.getCustomerDetails();
      await this.getCustomerGreyhoundEmails();
      await this.getCustomer();
      await this.getCustomerNotes();
      await this.getPurchasedItems();
      await this.getInquiredItems();

      this.$refs['customer-details-modal'].show();
    },
    resetCustomerDetailsModal() {
      this.customerNo = '';
    },
    async handleCustomerNotesTablePageChange(value) {
      this.queryCustomerNotes.page = value;
      await this.getCustomerNotes();
    },
    async handlePurchasedItemsTablePageChange(value) {
      this.queryParamsPurchasedItems.page = value;
      await this.getPurchasedItems();
    },
    async handleInquiredItemsTablePageChange(value) {
      this.queryParamsInquiredItems.page = value;
      await this.getInquiredItems();
    },
    validationItem(value) {
      return isNaN(value);
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
